import { SparklesIcon } from "@heroicons/vue/20/solid";

export const gettingStartedForContributors = {
  order: 1,
  name: "Getting started for Tower Hunt contributors",
  keywords: "validate prove proof earn reputation money contribute create",
  subcategory: "Getting started for new users",
  icon: SparklesIcon,
  iconColor: "text-emerald-500",
  markdown: `# Getting started for Tower Hunt contributors
  Contributing to Tower Hunt is an opportunity to earn money in a new way in real estate. If you're considering getting involved, this guide will help you build confidence and possibly earn your first bit of income. Before you dive in, make sure you're able to {% inlineRouterLink articleId="sign-in-to-tower-hunt" %}sign in to your account{% /inlineRouterLink %}.

  ## What to expect

  In order to contribute, you need three things:

  - A {% inlineRouterLink articleId="verify-your-email-address" %}verified account{% /inlineRouterLink %}
  - A {% inlineRouterLink %}positive credit balance{% /inlineRouterLink %} or a {% inlineRouterLink articleId="add-a-payment-method" %}payment method{% /inlineRouterLink %}
  - At least one {% inlineRouterLink articleId="explored-areas" %}explored area{% /inlineRouterLink %}

  ## 1. Validate

  Contributions to the public layer of Tower Hunt's database go through a {% inlineRouterLink articleId="understand-validation" %}validation process{% /inlineRouterLink %} before becoming available to other users. Validating is a good way to begin contributing because it only involves voting on the accuracy of proposed datapoints, rather than inputting data directly. Validators can earn {% inlineRouterLink articleId="understand-reputation" %}reputation{% /inlineRouterLink %} and money, so it is a rewarding activity in its own right.

  {% tablessContainer marginStyles="mb-4" %}
  1. Find your {% inlineAppIcon iconName="shieldCheck" %}**available validations**{% /inlineAppIcon %} count in the upper-right. If it is green, click it. If not, {% inlineRouterLink articleId="explored-areas" %}explore a new area of the map{% /inlineRouterLink %} where you have real estate knowledge until it turns green.
  2. On the {% inlineAppIcon iconName="shieldCheck" %}**Available**{% /inlineAppIcon %} section of the {% inlineRouterLink %}Validations tab{% /inlineRouterLink %}, you will see a group of changes to review. Your job is to decide whether each proposed change is accurate.
  3. Vote on each change using one of the three options: {% inlineAppIcon iconName="check" %}**Accept**{% /inlineAppIcon %}, {% inlineAppIcon iconName="xMark" %}**Reject**{% /inlineAppIcon %}, or {% inlineAppIcon iconName="skip" %}**Skip**{% /inlineAppIcon %}. Changes may provide {% inlineRouterLink %}citations{% /inlineRouterLink %} to support their assertions. You may also {% inlineAppIcon iconName="rectangleStack" /%} {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}**view changes on the property diagram**{% /inlineRouterLink %}.
  4. Changes are finalized asynchronously, so you will not know the outcome right away. If the outcome matches your vote, you are rewarded with usage credits, a {% inlineRouterLink articleId="understand-reputation" %}reputation{% /inlineRouterLink %} boost, and a share of revenue from the datapoint. Nice work!
  ![Validation screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  {% callout type="caution" %}
  **Important:** Validation involves putting money at risk, which is called {% inlineRouterLink articleId="what-is-staking?" %}staking{% /inlineRouterLink %}. Learn more about the entire validation process in our {% inlineRouterLink articleId="understand-validation" %}guide to validation{% /inlineRouterLink %}.
  {% /callout %}

  ## 2. Prove knowledge

  Many {% inlineRouterLink articleId="understand-datapoint-colors" %}unlockable datapoints{% /inlineRouterLink %} offer an option to {% inlineRouterLink articleId="unlock-what-you-already-know" %}prove you already know the underlying data{% /inlineRouterLink %}. This is a great way to start contributing when you have knowledge of specific properties and investments.

  {% tablessContainer marginStyles="mb-4" %}
  1. Use {% inlineRouterLink articleId="search" %}search{% /inlineRouterLink %} or {% inlineRouterLink articleId="the-role-of-the-map" %}Big Map{% /inlineRouterLink %} to navigate to a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property page{% /inlineRouterLink %} where you have knowledge.
  2. Use the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %} and {% inlineRouterLink articleId="sidebar" %}sidebar{% /inlineRouterLink %} to locate an {% inlineRouterLink articleId="understand-datapoint-colors" %}unlockable datapoint{% /inlineRouterLink %}. Examples of provable datapoints include numerical values, dates, names, statuses, and involved companies or contacts.
  3. Click the {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoint{% /inlineRouterLink %} to access its full {% inlineRouterLink articleId="datapoint-interactions" %}action menu{% /inlineRouterLink %}. Instead of {% inlineRouterLink articleId="unlock-datapoints" sectionName="Slide to unlock" %}swiping to unlock{% /inlineRouterLink %}, use the input underneath to prove what you know. Click {% inlineAppIcon iconName="check" %}**Save**{% /inlineAppIcon %} when you're ready.
  4. If your proof matches the existing datapoint, it is unlocked and you are rewarded with a {% inlineRouterLink articleId="understand-reputation" %}reputation{% /inlineRouterLink %} boost and a share of revenue from the datapoint. Nice work!
  ![Proof of knowledge screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  {% callout type="caution" %}
  **Important:** Unsuccessful proofs are immediately {% inlineRouterLink articleId="understand-how-datapoints-become-public" %}proposed as changes{% /inlineRouterLink %} to the existing datapoint. This means you may incur {% inlineRouterLink articleId="what-is-staking?" %}staking{% /inlineRouterLink %} and {% inlineRouterLink articleId="understand-reputation" %}reputation{% /inlineRouterLink %} costs if your proposed change is rejected. Learn more about how {% inlineRouterLink articleId="unlock-what-you-already-know" %}proof of knowledge{% /inlineRouterLink %} works.
  {% /callout %}

  ## 3. Create a new property

  One of the easiest ways to be rewarded for contributing is to {% inlineRouterLink articleId="create-a-property" %}create a new property{% /inlineRouterLink %}. Choose a simple building in an area you know well and give it a try!

  {% callout type="note" %}
  **Note:** In addition to the aforementioned prerequisites, to create new datapoints you must be a {% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}reputable user{% /inlineRouterLink %}.
  {% /callout %}

  ## 4. Build confidence

  Now that you've made your first contribution to Tower Hunt, here are some ways to grow your pool of contributions:

  - Familiarize yourself with the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}, where you can easily add a wide variety of datapoints to {% inlineRouterLink articleId="what-is-a-property?" %}properties{% /inlineRouterLink %}.
  - Learn about your {% inlineRouterLink articleId="what-is-safezone?" %}Safezone{% /inlineRouterLink %} and what it means to {% inlineRouterLink articleId="declassify-datapoints" %}declassify datapoints{% /inlineRouterLink %}. This will help you keep important info private and earn revenue from info you're willing to offer to the market.
  - Click the {% inlineAppIcon iconName="questionMark" %}**question mark icon**{% /inlineAppIcon %} to open **Help** in Tower Hunt and find answers quickly.
  `,
};
